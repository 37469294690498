
import { IMyHomepageBoard, IMyHomepageDetail, IMyHomepagePost } from '@/types';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { deleteHomepagePost, getHomepagePost } from '@/api/myHomepagePost';
import NoticeDetail from './notice/detail.vue';
import InquireDetail from './inquire/detail.vue';
import ProductDetail from './product/detail.vue';

@Component({
  name: 'HomepageBoardDetail',
  components: {
    NoticeDetail,
    InquireDetail,
    ProductDetail,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private board!: IMyHomepageBoard

  get hasReplyAuth() {
    return UserModule.userId === this.detail.userDetail.userId && this.board.replyState;
  }

  get hasWriteAuth() {
    return UserModule.userId === this.detail.userDetail.userId || this.board.secretState;
  }

  mounted() {
    getHomepagePost(this.$route.params.postUid, String(this.$route.query.password)).then((res) => {
      this.post = res.data;
    }).catch((error) => {
      alert(error.response.data.message);
      this.$router.push({ name: 'HomepageBoardIndex', params: { nickname: this.$route.params.nickname, boardUid: this.$route.params.boardUid } });
    });
  }

  private post: IMyHomepagePost | null = null;

  private handleClickRemove() {
    if (window.confirm('정말로 게시글을 삭제하시겠습니까?')) {
      deleteHomepagePost(this.$route.params.postUid, String(this.$route.query.password)).then(() => {
        alert('게시글이 삭제되었습니다.');
        this.$router.push({ name: 'HomepageBoardIndex', params: { boardUid: this.$route.params.boardUid } });
      });
    }
  }
}
