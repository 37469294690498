
import { IMyHomepagePost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageBoardProductDetail',
})
export default class extends Vue {
  @Prop({ required: true }) private post!: IMyHomepagePost

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private getLink() {
    if (this.post.option2) {
      if (this.post.option2.startsWith('http')) return this.post.option2;
      return `http://${this.post.option2}`;
    }
    return '#';
  }
}
