
import { IMyHomepagePost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageBoardInquireDetail',
})
export default class extends Vue {
  @Prop({ required: true }) private post!: IMyHomepagePost
}
